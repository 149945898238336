import React from 'react'

const About = () => {
	return (
		<div id="about">
		<div className="about-div">
			<div className="about-txt">
				<h2 className="about-title">Hi there!</h2>
				<p className="about-p">
					I'm Carly! I am an energetic, motivated Software Engineer currently working as a Fullstack Developer in Boston, Massachusetts. Previously I lived in New York City, working both in the restaurant industry and in performing arts, until attending the Flatiron School in Downtown New York.
				</p>
			</div>
		</div>
		</div>
	)
}

export default About