import React from 'react'
import About from '../components/About'
import Tech from '../components/Tech'

const HomeContainer = () => {
	return(
		<>
			<About />
			<Tech />
		</>
	)
}

export default HomeContainer